<template>
  <div>
    <img
      class="hidden-sm-and-down"
      style="width: 100%"
      src="../../../assets/mock/us/组 7471.png"
      alt=""
    />
    <img
      class="hidden-md-and-up"
      style="width: 100%"
      src="../../../assets/mock/us/新闻中心.png"
      alt=""
    />
    <div class="warp" style=" min-height: 60vh; overflow: hidden;">
      <div class="top">
        <h3>{{ list.title }}</h3>
        <span></span>
        <p>{{ list.isAddTime }}</p>
      </div>
      <div v-html="list.content"></div>
      <h5 style="margin: 1.875rem 0; text-align: right;">
        发布时间：{{ list.isAddTime }} | 责任编辑：{{
          list.author
        }}
      </h5>
    </div>
  </div>
</template>

<script>
import { getNewAbout } from "../../../api/home";

export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getLIst();
  },
  methods: {
    getLIst() {
      let id = this.$route.query.id;
      getNewAbout(id).then((res) => {
        console.log(res);
        this.list = res.data.code;
        console.log(this.list);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  text-align: center;
  h3 {
    margin-top: 1.25rem;
  }
  span {
    width: 1.25rem;
    height: 3px;
    display: inline-block;
    background-color: #3cb59e;
  }
}
img{
    width: 100% !important;
}
</style>
